import React from 'react';
import NavCardList from '../../Partials/Layouts/NavCardList/NavCardList';
import NavCard from '../../Partials/Layouts/NavCardList/NavCard/NavCard';
import { RiArticleLine, RiImageLine } from 'react-icons/ri';

function Dashboard() {
  return (
    <NavCardList numOfCards={'four'}>
      <NavCard cardName={'Hero Sliders'} navCardLink={'/heroSliders'}>
        <RiImageLine />
      </NavCard>
      <NavCard cardName={'About Me'} navCardLink={'/aboutMe'}>
        <RiArticleLine />
      </NavCard>
    </NavCardList>
  );
}

export default Dashboard;
