import axios from 'axios';
import { useState, useEffect } from 'react';

import CreateAboutMe from './CreateAboutMe';
import UpdateAboutMe from './UpdateAboutMe';
import { ABOUT_ME_API } from '../../../Utilities/APIs';
import CRUDTable from '../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTable';
import EditButton from '../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/EditButton/EditButton';
import CRUDth from '../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDth/CRUDth';
import CRUDTableRow from '../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableRow/CRUDTableRow';
import CRUDTableHeader from '../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDTableHeader';
import CreateButton from '../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDDashboardBoilerplate/CRUDHeader/CreateButton/CreateButton';
import Modal from '../../Partials/Elements/Modal/Modal';

function AboutMe() {
  const [data, setData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [showDeleteSection, setShowDeleteSection] = useState(false);
  const [toggleFetch, setToggleFetch] = useState(false);

  useEffect(() => {
    async function fetchAboutMe() {
      const { data } = await axios.get(ABOUT_ME_API);
      setData(data[0]);
    }
    fetchAboutMe();
  }, [toggleFetch]);

  function handleClose() {
    setShowModal(false);
    setShowCreateForm(false);
    setShowUpdateForm(false);
    setShowDeleteSection(false);
  }

  function triggerFetch() {
    setToggleFetch((prevState) => !prevState);
  }

  return (
    <>
      <div className='crud_holder'>
        <div className='body_header'>
          <h1 className='heading'>{`About Me`}</h1>
          {!data && (
            <CreateButton
              screenTopicSingular='About Me'
              setShowModal={setShowModal}
              setShowCreateForm={setShowCreateForm}
            />
          )}
        </div>
        <CRUDTable>
          <CRUDTableHeader>
            <CRUDth th='Description' />
            <CRUDth th='' />
          </CRUDTableHeader>
          <tbody>
            <CRUDTableRow>
              <p
                dangerouslySetInnerHTML={{ __html: data?.description }}
                style={{ whiteSpace: 'pre-wrap', textAlign: 'justify' }}
              />
              <td className='action_button_cell'>
                <EditButton
                  setShowModal={setShowModal}
                  setShowUpdateForm={setShowUpdateForm}
                  targetID={data?._id}
                />
              </td>
            </CRUDTableRow>
          </tbody>
        </CRUDTable>
      </div>
      {showModal && (
        <Modal
          view
          handleClose={handleClose}
          modalHeading={
            (showCreateForm && `Create About Me`) ||
            (showUpdateForm && `Update About Me`) ||
            (showDeleteSection && `Delete About ME`)
          }
        >
          {showCreateForm && !data && (
            <CreateAboutMe
              setShowCreateForm={setShowCreateForm}
              setShowModal={setShowModal}
              triggerFetch={triggerFetch}
            />
          )}
          {showUpdateForm && (
            <UpdateAboutMe
              data={data}
              setShowUpdateForm={setShowUpdateForm}
              setShowModal={setShowModal}
              triggerFetch={triggerFetch}
            />
          )}
        </Modal>
      )}
    </>
  );
}

export default AboutMe;
