import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Modal from "../../Partials/Elements/Modal/Modal";
import CreateButton from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDDashboardBoilerplate/CRUDHeader/CreateButton/CreateButton";
import DeleteButton from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/DeleteButton/DeleteButton";
import EditButton from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/EditButton/EditButton";
import ViewButton from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/ViewButton/ViewButton";
import CRUDTable from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTable";
import ImageCell from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ImageCell/ImageCell";
import CRUDTableHeader from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDTableHeader";
import CRUDth from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDth/CRUDth";
import CRUDTableRow from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableRow/CRUDTableRow";
import DeleteItem from "../../Partials/Layouts/DeleteItem/DeleteItem";
import CreateGalleryImage from "./CreateGalleryImage";
import UpdateGalleryImage from "./UpdateGalleryImage";
import axios from "axios";
import { GALLERY_IMAGE_API } from "../../../Utilities/APIs";

function GalleryImage() {
  const { galleyId } = useParams();

  const [showViewSection, setShowViewSection] = useState("");
  const [images, setImages] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [targetID, setTargetID] = useState(null);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [showDeleteSection, setShowDeleteSection] = useState(false);
  const [toggleFetch, setToggleFetch] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    async function fetchAndSetStreets() {
      const { data } = await axios.get(
        GALLERY_IMAGE_API + "getAllImagesByGalleryId/" + galleyId
      );
      setImages(data);
    }
    fetchAndSetStreets();
  }, [axios, toggleFetch, galleyId]);

  function handleClose() {
    setShowModal(false);
    setShowCreateForm(false);
    setShowUpdateForm(false);
    setShowDeleteSection(false);
  }

  function triggerFetch() {
    setToggleFetch((prevState) => !prevState);
  }

  return (
    <>
      <div className="crud_holder">
        <div className="body_header">
          <h1 className="heading">
            Gallery Images ({images?.length} in total)
          </h1>

          <CreateButton
            screenTopicSingular={"Gallery Image"}
            setShowCreateForm={setShowCreateForm}
            setShowModal={setShowModal}
          />
        </div>
        <CRUDTable>
          <CRUDTableHeader>
            <CRUDth th="Images" />
            <CRUDth th="Actions" />
          </CRUDTableHeader>
          <tbody>
            {images &&
              images?.map((image) => (
                <CRUDTableRow key={image._id}>
                  <ImageCell imgSrc={image?.image} alt={"team member"} />

                  <td className="action_button_cell">
                    <ViewButton
                      setShowModal={setShowModal}
                      setShowViewSection={setShowViewSection}
                      targetID={image._id}
                      setTargetID={setTargetID}
                    />
                    <EditButton
                      setShowModal={setShowModal}
                      setShowUpdateForm={setShowUpdateForm}
                      targetID={image._id}
                      setTargetID={setTargetID}
                    />
                    <DeleteButton
                      setShowModal={setShowModal}
                      setShowDeleteSection={setShowDeleteSection}
                      targetID={image._id}
                      setTargetID={setTargetID}
                    />
                  </td>
                </CRUDTableRow>
              ))}
          </tbody>
        </CRUDTable>
      </div>

      {showModal && (
        <Modal
          handleClose={handleClose}
          modalHeading={
            (showCreateForm && `Create Gallery`) ||
            (showUpdateForm && `Update Street `) ||
            (showDeleteSection && `Delete Street`)
          }
        >
          {showCreateForm && (
            <CreateGalleryImage
              galleyId={galleyId}
              setShowCreateForm={setShowCreateForm}
              setShowModal={setShowModal}
              triggerFetch={triggerFetch}
            />
          )}
          {showUpdateForm && (
            <UpdateGalleryImage
              galleyId={galleyId}
              targetID={targetID}
              setShowUpdateForm={setShowUpdateForm}
              setShowModal={setShowModal}
              triggerFetch={triggerFetch}
            />
          )}

          {showDeleteSection && (
            <DeleteItem
              api={GALLERY_IMAGE_API}
              targetID={targetID}
              setShowModal={setShowModal}
              setShowDeleteSection={setShowDeleteSection}
              triggerFetch={triggerFetch}
            />
          )}
        </Modal>
      )}
    </>
  );
}

export default GalleryImage;
