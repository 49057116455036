import React from 'react'
import NCRUD from '../../Partials/Layouts/CRUDs/CRUDLayouts/NCRUD/NCRUD'
import { VIDEO_CATEGORIES_API } from '../../../Utilities/APIs'
import { RiImage2Line } from 'react-icons/ri'

function VideoCategory() {
    return (
        <NCRUD
            // common props
            api={VIDEO_CATEGORIES_API}
            screenTopicSingular='Video Category'
            screenTopicPlural='Video Categories'


            // crud header props
            showTotalInHeading


            // action button props
            extraActionButton
            extraActionButtonHandleClick='/videos'
            extraActionButtonChildren={<RiImage2Line />}


            // crud props
            allowCreate
            allowUpdate
        // allowDelete

        />
    )
}

export default VideoCategory