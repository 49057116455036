import { useEffect, useState } from "react";

import Form from "../../Partials/Layouts/Forms/Form";
import ImageInput from "../../Partials/Layouts/Forms/FormInputs/ImageInput/ImageInput";
import ShortTextInput from "../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput";
import FormSubmitButton from "../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";
import { GALLERY_API } from "../../../Utilities/APIs";
import axios from "axios";

function UpdateGallery({
  targetID,
  triggerFetch,
  setShowUpdateForm,
  setShowModal,
  handleClose,
}) {
  const [title, setTitle] = useState("");
  const [cardImage, setCardImage] = useState("");
  const [description, setDescription] = useState("");

  useEffect(() => {
    async function fetchAndSetProductInfo() {
      const { data } = await axios.get(
        GALLERY_API + "getSingleGallery/" + targetID
      );
      setTitle(data.title);
      setCardImage(data.cardImage);
      setDescription(data.description);
    }
    fetchAndSetProductInfo();
  }, [targetID, axios]);

  async function handleSubmit(e) {
    e.preventDefault();

    const itemData = new FormData();

    itemData.append("title", title);
    itemData.append("cardImage", cardImage);
    itemData.append("description", description);

    const response = await axios.patch(GALLERY_API + targetID, itemData);

    if (response) {
      setShowUpdateForm(false);
      setShowModal(false);
      triggerFetch();
      handleClose();
    }
  }
  return (
    <Form onSubmit={handleSubmit} hasImage>
      <ShortTextInput
        label={`Title`}
        value={title}
        placeholder={`Enter title`}
        setState={setTitle}
      />

      <ShortTextInput
        label={`Description`}
        value={description}
        placeholder={`Enter Description`}
        setState={setDescription}
      />
      <ImageInput
        fieldId="1"
        state={cardImage}
        setState={setCardImage}
        allowUpdateImage
      >
        Upload Card Image
      </ImageInput>
      <FormSubmitButton text="Update Gallery Image" />
    </Form>
  );
}

export default UpdateGallery;
