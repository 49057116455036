import axios from 'axios';
import { useState } from 'react';
import Form from '../../Partials/Layouts/Forms/Form';
import { ABOUT_ME_API } from '../../../Utilities/APIs';
import CustomEditor from '../../Partials/Elements/CustomEditor/CustomEditor';
import FormSubmitButton from '../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton';

function UpdateAboutMe({
  data = '',
  setShowUpdateForm,
  setShowModal,
  triggerFetch,
}) {
  const [aboutMe, setAboutME] = useState(data?.description);

  async function handleSubmit(e) {
    e.preventDefault();
    const response = await axios.patch(ABOUT_ME_API + data._id, {
      description: aboutMe,
    });

    if (response) {
      setShowUpdateForm(false);
      setShowModal(false);

      triggerFetch();
    }
  }
  return (
    <>
      <Form onSubmit={handleSubmit} hasImage>
        <CustomEditor data={aboutMe} setState={setAboutME} />

        <FormSubmitButton text='Save' />
      </Form>
    </>
  );
}

export default UpdateAboutMe;
