import React from 'react'
import {  FEATURED_VIDEOS_API, VIDEOS_API } from '../../../Utilities/APIs'
import PSCRUD from '../../Partials/Layouts/CRUDs/CRUDLayouts/PSCRUD/PSCRUD'

function FeaturedVideos() {
  return (
    <PSCRUD
      parentDBField='parent-child'
      // common props
      api={FEATURED_VIDEOS_API}
      screenTopicSingular={'Featured Video'}
      screenTopicPlural={'Featured Videos'}

      // crud header props
      showTotalInHeading

      // crud props
      allowCreate
      allowUpdate
      allowDelete

      // field props
      maxPrecedence={8}

      // select props
      selectApi={VIDEOS_API}
      selectType='parent-child'
      selectDBField='video'
      selectName='Video'
    />
  )
}

export default FeaturedVideos