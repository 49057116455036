import axios from 'axios';
import { useState } from 'react';
import Form from '../../Partials/Layouts/Forms/Form';
import { ABOUT_ME_API } from '../../../Utilities/APIs';
import CustomEditor from '../../Partials/Elements/CustomEditor/CustomEditor';
import FormSubmitButton from '../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton';

function CreateAboutMe({ setShowCreateForm, setShowModal, triggerFetch }) {
  const [aboutMe, setAboutME] = useState('');

  async function handleSubmit(e) {
    e.preventDefault();
    const response = await axios.post(ABOUT_ME_API, { description: aboutMe });

    if (response) {
      setShowCreateForm(false);
      setShowModal(false);

      triggerFetch();
    }
  }
  return (
    <>
      <Form onSubmit={handleSubmit} hasImage>
        <CustomEditor setState={setAboutME} />

        <FormSubmitButton text='Save' />
      </Form>
    </>
  );
}

export default CreateAboutMe;
