import axios from "axios";
import { useEffect, useState } from "react";
import { APP_SETTINGS_API } from "../../../../Utilities/APIs";
import Image from "../Image/Image";

function Logo() {
  // const [logo,] = useState(null)
  const [logo, setLogo] = useState(null);

  useEffect(() => {
    async function getAndSetLogo() {
      const { data } = await axios.get(APP_SETTINGS_API);
      setLogo(data[0].image);
    }
    getAndSetLogo();
  }, []);

  return logo && <Image imgLink={logo} imgAlt="Logo" />;
}

export default Logo;
