import { useState } from "react";
import axios from "axios";
import { GALLERY_IMAGE_API } from "../../../Utilities/APIs";
import Form from "../../Partials/Layouts/Forms/Form";
import ImageInput from "../../Partials/Layouts/Forms/FormInputs/ImageInput/ImageInput";
import FormSubmitButton from "../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";

function CreateGalleryImage({
  setShowCreateForm,
  setShowModal,
  triggerFetch,
  galleyId,
}) {
  const [image, setImage] = useState("");

  async function handleSubmit(e) {
    e.preventDefault();
    const itemData = new FormData();
    itemData.append("image", image);

    console.log(image);
    const response = await axios.post(GALLERY_IMAGE_API + galleyId, itemData);

    console.log(response);
    if (response.data) {
      setShowCreateForm(false);
      setShowModal(false);
      triggerFetch();
    }
  }

  return (
    <Form onSubmit={handleSubmit} hasImage>
      <ImageInput
        fieldId="1"
        state={image}
        setState={setImage}
        allowCreateImage
      >
        Upload Gallery Image
      </ImageInput>
      <FormSubmitButton text="Create Gallery Image" />
    </Form>
  );
}

export default CreateGalleryImage;
