import { useEffect, useState } from "react";

import Form from "../../Partials/Layouts/Forms/Form";
import ImageInput from "../../Partials/Layouts/Forms/FormInputs/ImageInput/ImageInput";
import FormSubmitButton from "../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";
import axios from "axios";
import { GALLERY_IMAGE_API } from "../../../Utilities/APIs";

function UpdateGalleryImage({
  targetID,
  triggerFetch,
  setShowUpdateForm,
  setShowModal,
}) {
  const [image, setImage] = useState("");

  useEffect(() => {
    async function fetchAndSetProductInfo() {
      const { data } = await axios.get(GALLERY_IMAGE_API + targetID);
      setImage(data.image);
    }
    fetchAndSetProductInfo();
  }, [targetID, axios]);

  async function handleSubmit(e) {
    e.preventDefault();

    const itemData = new FormData();

    itemData.append("image", image);

    const response = await axios.patch(GALLERY_IMAGE_API + targetID, itemData);

    if (response) {
      setShowUpdateForm(false);
      setShowModal(false);
      triggerFetch();
    }
  }
  return (
    <Form onSubmit={handleSubmit} hasImage>
      <ImageInput
        fieldId="1"
        state={image}
        setState={setImage}
        allowUpdateImage
      >
        Update Card Image
      </ImageInput>
      <FormSubmitButton text="Update Gallery Image" />
    </Form>
  );
}

export default UpdateGalleryImage;
