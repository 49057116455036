import React from 'react'
import PSCRUD from '../../Partials/Layouts/CRUDs/CRUDLayouts/PSCRUD/PSCRUD'
import { BLOGS_API, FEATURED_BLOGS_API } from '../../../Utilities/APIs'

function FeaturedBlogs() {
    return (
        <PSCRUD
        parentDBField='parent-child'
        // common props
        api={FEATURED_BLOGS_API}
        screenTopicSingular={'Featured Blog'}
        screenTopicPlural={'Featured Blogs'}
  
        // crud header props
        showTotalInHeading
  
        // crud props
        allowCreate
        allowUpdate
        allowDelete
  
        // field props
        maxPrecedence={8}
  
        // select props
        selectApi={BLOGS_API}
        selectType='parent-child'
        selectDBField='blog'
        selectName='Blog'
      />
    )
}

export default FeaturedBlogs