import { Routes, Route, Navigate, BrowserRouter } from "react-router-dom";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../Redux/Features/Auth/authSlice";

import "./App.css";

import NavBar from "./Partials/Sections/NavBar/NavBar";
import SideBar from "./Partials/Sections/SideBar/SideBar";
import Login from "./Screens/AuthScreens/Login/Login";
import Dashboard from "./Screens/Dashboard/Dashboard";
import InviteEmployees from "./Screens/AuthScreens/InviteEmployees/InviteEmployees";
import EmployeesScreen from "./Screens/AuthScreens/EmployeesScreen";
import Employees from "./Screens/AuthScreens/Employees/Employees";
import AppSettings from "./Screens/AppSettings/AppSettings";
import Register from "./Screens/AuthScreens/Register/Register";
import SettingsScreen from "./Screens/Settings/SettingsScreen";
import RecoverPassOne from "./Screens/AuthScreens/RecoverPassOne";
import RecoverPassThree from "./Screens/AuthScreens/RecoverPassThree";

import ContactInfosScreen from "./Screens/ContactInfosScreen/ContactInfosScreen";
import UsersScreen from "./Screens/UsersScreen/UsersScreen";
import HeroSliders from "./Screens/HomeScreen/HeroSliders";

import Customers from "./Screens/UsersScreen/Customers/Customers";
import EmployeeScreen from "./Screens/AuthScreens/Employees/EmployeeScreen";
import Blogs from "./Screens/Blogs/Blogs";
import About from "./Screens/About/About";
import SocialLinks from "./Screens/SocialLinks/SocialLinks";
import VideoCategory from "./Screens/VideoCategoryScreen/VideoCategory";
import Videos from "./Screens/VideoCategoryScreen/Videos/Videos";
import FeaturedVideos from "./Screens/VideoCategoryScreen/FeaturedVideos";
import FeaturedBlogs from "./Screens/Blogs/FeaturedBlogs";
import GalleryContent from "./Screens/GalleryContent/GalleryContent";
import GalleryImage from "./Screens/GalleryContent/GalleryImage";
import AboutMe from "./Screens/AboutMe/AboutMe";

function App() {
  const { employee } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  axios.interceptors.response.use(
    (res) => {
      return res;
    },
    (err) => {
      if (err.response.status === 401) {
        dispatch(logout());
      }
      return Promise.reject(err);
    }
  );

  return (
    <section>
      <BrowserRouter>
        <NavBar />
        <main>
          {employee && <SideBar />}
          <section className="screen-container">
            <Routes>
              <Route
                path="/login"
                element={!employee ? <Login /> : <Navigate to={"/"} />}
              />
              <Route
                path="/register/:token"
                element={!employee ? <Register /> : <Navigate to={"/"} />}
              />
              <Route path="/recover-pass-one" element={<RecoverPassOne />} />
              <Route
                path="/resetEmployeePassword/:token"
                element={<RecoverPassThree />}
              />

              <Route
                path="/employeeScreens"
                element={
                  employee ? <EmployeesScreen /> : <Navigate to={"/login"} />
                }
              />
              <Route
                path="/employeeInvites"
                element={
                  employee ? <InviteEmployees /> : <Navigate to={"/login"} />
                }
              />
              <Route
                path="/employeeScreen"
                element={
                  employee ? <EmployeeScreen /> : <Navigate to={"/login"} />
                }
              />
              <Route
                path="/employees"
                element={employee ? <Employees /> : <Navigate to={"/login"} />}
              />
              <Route
                path="/customers"
                element={employee ? <Customers /> : <Navigate to={"/login"} />}
              />

              <Route
                path="/"
                element={employee ? <Dashboard /> : <Navigate to={"/login"} />}
              />
              <Route
                path="/heroSliders"
                element={
                  employee ? <HeroSliders /> : <Navigate to={"/login"} />
                }
              />
              <Route
                path="/aboutMe"
                element={
                  employee ? <AboutMe /> : <Navigate to={"/login"} />
                }
              />
              <Route
                path="/blogs"
                element={employee ? <Blogs /> : <Navigate to={"/login"} />}
              />
              <Route
                path="/about"
                element={employee ? <About /> : <Navigate to={"/login"} />}
              />
              <Route
                path="/gallery"
                element={
                  employee ? <GalleryContent /> : <Navigate to={"/login"} />
                }
              />
              <Route
                path="/gallery/:galleyId"
                element={
                  employee ? <GalleryImage /> : <Navigate to={"/login"} />
                }
              />
              <Route
                path="/socialLinks"
                element={
                  employee ? <SocialLinks /> : <Navigate to={"/login"} />
                }
              />
              <Route
                path="/videoCategory"
                element={
                  employee ? <VideoCategory /> : <Navigate to={"/login"} />
                }
              />
              <Route
                path="/videos/:Id"
                element={employee ? <Videos /> : <Navigate to={"/login"} />}
              />
              <Route
                path="/featuredVideos"
                element={
                  employee ? <FeaturedVideos /> : <Navigate to={"/login"} />
                }
              />
              <Route
                path="/featuredBlogs"
                element={
                  employee ? <FeaturedBlogs /> : <Navigate to={"/login"} />
                }
              />

              <Route
                path="/settingsScreen"
                element={
                  employee ? <SettingsScreen /> : <Navigate to={"/login"} />
                }
              />
              <Route
                path="/appSettings"
                element={
                  employee ? <AppSettings /> : <Navigate to={"/login"} />
                }
              />
              <Route
                path="/contactInfos"
                element={
                  employee ? <ContactInfosScreen /> : <Navigate to={"/login"} />
                }
              />
              <Route
                path="/usersScreen"
                element={
                  employee ? <UsersScreen /> : <Navigate to={"/login"} />
                }
              />
            </Routes>
          </section>
        </main>
      </BrowserRouter>
    </section>
  );
}

export default App;
