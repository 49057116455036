import { useState } from "react";

import axios from "axios";
import { GALLERY_API } from "../../../Utilities/APIs";
import Form from "../../Partials/Layouts/Forms/Form";
import ShortTextInput from "../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput";
import ImageInput from "../../Partials/Layouts/Forms/FormInputs/ImageInput/ImageInput";
import FormSubmitButton from "../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";

function CreteGallery(
  setShowCreateForm,
  setShowModal,
  triggerFetch,
  handleClose,
  storeId
) {
  const [title, setTitle] = useState("");
  const [cardImage, setCardImage] = useState("");
  const [description, setDescription] = useState("");

  async function handleSubmit(e) {
    e.preventDefault();

    const itemData = new FormData();

    itemData.append("title", title);
    itemData.append("cardImage", cardImage);
    itemData.append("description", description);

    const response = await axios.post(GALLERY_API, itemData);
    if (response.data) {
      console.log("response", response);
      setShowCreateForm(false);
      setShowModal(false);
      triggerFetch();
      handleClose();
    }
  }

  console.log("image is", cardImage);
  return (
    <Form onSubmit={handleSubmit} hasImage>
      <ShortTextInput
        label={`Title`}
        value={title}
        placeholder={`Enter title`}
        setState={setTitle}
      />

      <ShortTextInput
        label={`Description`}
        value={description}
        placeholder={`Enter Description`}
        setState={setDescription}
      />

      <ImageInput
        fieldId="1"
        state={cardImage}
        setState={setCardImage}
        allowCreateImage
      >
        Upload Card Image
      </ImageInput>
      <FormSubmitButton text="Create Image" />
    </Form>
  );
}

export default CreteGallery;
