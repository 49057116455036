import { useState, useEffect } from "react"
import axios from "axios"
import { IMAGE_URL, VIDEOS_API } from "../../../../Utilities/APIs"

function ViewVideo({

    //common props
    targetID, employee

}) {

    const [vedio, setVedio] = useState(null)

    useEffect(() => {

        async function fetchAndSetVideoInfo() {

            const { data } = await axios.get(VIDEOS_API + 'getSingleVideo/' + targetID)
            setVedio(data);
        }
        fetchAndSetVideoInfo()
    }, [targetID])

    return (
        <div className="crud_view_content">

            {vedio &&
                <>
                    <h1>Name</h1>
                    <p>{vedio.name}</p>
                    <h1>Image</h1>
                    <img src={IMAGE_URL + vedio.image} alt="" />
                    <h1>Link</h1>
                    <p>{vedio.link}</p>
                    <h1>Date</h1>
                    <p>{new Date(vedio?.date).toDateString()}</p>
                    

                </>
            }
        </div>
    )
}

export default ViewVideo