import SmallSideBarItem from "./SmallSideBarItem/SmallSideBarItem";

import {
  RiBook2Line,
  RiGroupLine,
  RiHome2Line,
  RiInformationLine,
  RiSettings2Line,
} from "react-icons/ri";
import { IoIosImages } from "react-icons/io";
import { FaImages } from "react-icons/fa";

import "./SideBar.css";

function SideBar() {
  return (
    <>
      <section className="small_sidebar">
        <div className="container">
          <SmallSideBarItem link="/" dataTip="Dashboard" dataFor="dashboard">
            <RiHome2Line />
          </SmallSideBarItem>
          <SmallSideBarItem link="/blogs" dataTip="Blogs" dataFor="blogs">
            <RiBook2Line />
          </SmallSideBarItem>
          <SmallSideBarItem link="/about" dataTip="About" dataFor="about">
            <RiInformationLine />
          </SmallSideBarItem>
          <SmallSideBarItem link="/gallery" dataTip="Gallery" dataFor="Gallery">
            <FaImages />
          </SmallSideBarItem>
          {/* <SmallSideBarItem
                        link='/videoCategory'
                        dataTip='Video'
                        dataFor='Video'
                    >
                        <RiVideoLine />
                    </SmallSideBarItem> */}

          <SmallSideBarItem link="/usersScreen" dataTip="Users" dataFor="Users">
            <RiGroupLine />
          </SmallSideBarItem>

          <SmallSideBarItem
            link="/settingsScreen"
            dataTip="Settings"
            dataFor="settings"
          >
            <RiSettings2Line />
          </SmallSideBarItem>
        </div>
      </section>
    </>
  );
}

export default SideBar;
