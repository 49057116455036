import './MediaGallery.css'
import { useState } from 'react';
import MediaFolderView from './MediaFolderView/MediaFolderView';
import MediaLists from './MediaLists/MediaLists';
import { useEffect } from 'react';


function MediaGallery({ initialScreen }) {

  const [screen, setScreen] = useState('initial');
  const [selectedCollection, setSelectedCollection] = useState('initial');

  useEffect(() => {
    if (initialScreen) {
      setScreen(initialScreen)
    }
  }, [initialScreen]);

  return (
    <>
      {
        screen === 'mediaList'
          ?
          <MediaLists
          selectedCollection={selectedCollection}
          />
          :

          <MediaFolderView
            screen={screen}
            setScreen={setScreen}
            setSelectedCollection={setSelectedCollection}
          />
      }
    </>
  )
}

export default MediaGallery