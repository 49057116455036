import { useState } from "react"
import axios from "axios"
import ShortTextInput from "../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput"
import ImageInput from "../../../Partials/Layouts/Forms/FormInputs/ImageInput/ImageInput"
import FormSubmitButton from "../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton"
import Form from "../../../Partials/Layouts/Forms/Form"
import { VIDEOS_API } from "../../../../Utilities/APIs"
import { useEffect } from "react"


function UpdateVideo({

    Id, targetID, employee, setShowUpdateForm, setShowModal, triggerFetch

}) {

    const [name, setName] = useState('')
    const [image, setImage] = useState('')
    const [link, setLink] = useState('')
    const [date, setDate] = useState(null)

    useEffect(() => {

        async function fetchAndSetVideoInfo() {
            const { data } = await axios.get(VIDEOS_API + 'getSingleVideo/' + targetID)
            const dateObject = new Date(data.date)
            setDate(`${dateObject.toISOString().slice(0, 10)}`)
            setName(data.name);
            setImage(data.image);
            setLink(data.link);
        }
        fetchAndSetVideoInfo()
    }, [targetID])

    async function handleSubmit(e) {

        e.preventDefault()

        // const config = {
        //     headers: {
        //         Authorization: `Bearer ${employee.token}`
        //     }
        // }
        const itemData = new FormData()

        itemData.append('videoCategory', Id)
        itemData.append('name', name)
        itemData.append('image', image)
        itemData.append('link', link)
        itemData.append('date', date)

        const response = await axios.patch(VIDEOS_API + targetID, itemData)

        if (response) {

            setShowUpdateForm(false);
            setShowModal(false);
            triggerFetch()
        }

    }

    return (
        <Form onSubmit={handleSubmit} hasImage>
            <ShortTextInput
                label='Name'
                value={name}
                placeholder={`Enter Name`}
                setState={setName}
            />
            <ShortTextInput
                label='Link'
                placeholder={`Enter Link`}
                value={link}
                setState={setLink}
            />
            <ImageInput
                fieldId='1'
                state={image}
                setState={setImage}
                allowUpdateImage
            >
                Upload Card Image
            </ImageInput>
            <>
                <label className="input_field_label caption bold">Date</label>

                <input
                    className='input_field'
                    type='date'
                    value={date}
                    placeholder='Enter Date'
                    onChange={e => setDate(e.target.value)}
                />

            </>

            <FormSubmitButton text='Update Video' />
        </Form>
    )
}

export default UpdateVideo