import { useEffect, useState } from "react";

import Image from "../../Partials/Elements/Image/Image";
import { GALLERY_API } from "../../../Utilities/APIs";
import axios from "axios";

function ViewGallery({ targetID }) {
  const [galleryInfo, setGalleryIngo] = useState(null);

  useEffect(() => {
    async function fetchAndsetGalleryIngo() {
      const { data } = await axios.get(
        GALLERY_API + "getSingleGallery/" + targetID
      );
      setGalleryIngo(data);
    }
    fetchAndsetGalleryIngo();
  }, [targetID, axios]);

  return (
    <div className="crud_view_content">
      {galleryInfo && (
        <>
          <h1>Title</h1>
          <p>{galleryInfo?.title}</p>

          <h1>Description</h1>
          <p>{galleryInfo?.description}</p>

          <h1>Card Image</h1>
          <Image imgLink={galleryInfo?.cardImage} imgAlt={"Card Front Image"} />
        </>
      )}
    </div>
  );
}

export default ViewGallery;
